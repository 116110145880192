<template>
  <div>
    <div class="subheader subheader-transparent pb-5" id="kt_subheader">
      <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap py-0">
        <div class="d-flex align-items-center flex-wrap mr-2">
          <h5 class="text-dark font-weight-bolder mt-2 mb-2 mr-5">{{ $t('spaces.title') }} {{ setCoworkingName }}</h5>
          <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"></div>
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bolder" id="kt_subheader_total">{{ $t('spaces.count', {count: countList}) }}</span>
          </div>
        </div>
        <div class="d-flex align-items-center flex-wrap mr-2"></div>
      </div>
    </div>

    <div v-if="!loaded" class="container py-0">
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b" v-for="index in 2" :key="index">
            <div class="card-body">
              <!--begin::Top-->
              <div class="d-flex flex-wrap flex-md-nowrap">
                <!--begin::Pic-->
                <div class="mr-md-7 slider-width">
                  <PuSkeleton height="300px" width="100%"></PuSkeleton>
                </div>
                <!--end::Pic-->
                <!--begin: Info-->
                <div class="mt-5 mt-md-0" style="width:100%; height:100%">
                  <div class="mb-5" v-for="ind in 3" :key="ind">
                    <PuSkeleton width="100%" height="20px" :count="1"></PuSkeleton>
                    <PuSkeleton width="100%" :count="3"></PuSkeleton>
                  </div>
                </div>
                <!--end::Info-->
              </div>
              <!--end::Top-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loaded" class="container py-0">
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b" v-for="space in spaceList" :key="space.id">
            <div class="card-body">
              <!--begin::Top-->
              <div class="d-flex flex-wrap flex-md-nowrap">
                <!--begin::Pic-->
                <div v-if="space.images" class="mr-md-7 slider-width">
                  <VueSlickCarousel :arrows="true" :dots="false" :adaptiveHeight="false">
                    <div v-for="image in space.images" :key="image.id">
                      <img :src="image | photoUrl(setImageWidth)" height="300" style="object-fit: cover">
                    </div>
                    <template #prevArrow="arrowOption">
                      <div class="custom-arrow-left">
                        <i class="fas fa-chevron-circle-left"></i>
                      </div>
                    </template>
                    <template #nextArrow="arrowOption">
                      <div class="custom-arrow custom-arrow-right">
                        <i class="fas fa-chevron-circle-right"></i>
                      </div>
                    </template>
                  </VueSlickCarousel>
                </div>
                <!--end::Pic-->
                <!--begin: Info-->
                <div class="d-flex flex-column justify-content-between mt-5 mt-md-0 w-75">
                  <div>
                    <!--begin::Title-->
                    <div class="d-flex align-items-center justify-content-between flex-wrap mt-2 mt-md-0">
                      <div class="mr-3">
                        <!--begin::Name-->
                        <router-link
                            :to="'/spaces/' + space.id"
                            v-slot="{ href, route, navigate, isActive, isExactActive }"
                        >
                          <a :href="href" @click="navigate" class="text-dark text-hover-primary font-size-h4 font-weight-bolder">{{space.name}}</a>
                        </router-link>
                        <!--end::Name-->
                        <!--begin::Address-->
                        <div class="d-flex flex-wrap my-2">
                          <a :href="'/spaces/' + space.id + '#address'" @click="go({path: `/spaces/${space.id}`, hash: 'address'})"
                             class="text-muted font-weight-bolder mr-lg-8 mr-5 mb-2 mb-lg-0">
                            <font-awesome-icon icon="map-marker-alt" class="mr-1"/>
                            {{space.address_full}}
                          </a>
                        </div>
                        <!--end::Address-->
                      </div>
                    </div>
                    <!--end::Title-->
                    <!--begin::Content-->
                    <!--            <div class="d-flex align-items-center flex-wrap justify-content-between">-->
                    <div class="d-flex flex-wrap align-items-center mt-2">
                      <div class="row">
                        <!--begin::Description-->
                        <div class="col-xl-6">
                        <p class="m-0 lines-count lines-count-5 pr-xl-5" v-html="space.description"></p>
                        </div>
                        <!--end::Description-->
                        <!--begin::Info-->
                        <div class="col-xl-6">
                          <div class="pl-xl-5 mt-5 mt-xl-0">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                              <span class="text-muted font-weight-bolder mr-2">{{ $t('spaces.info.area') }}:</span>
                              <span class="text-primary font-weight-bolder">{{ $t('spaces.info.area_value', {
                                min: space.office_short_info.area[0] || 0,
                                max: space.office_short_info.area[1] || 0,
                              }) }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                              <span class="text-muted font-weight-bolder mr-2">{{ $t('spaces.info.capacity') }}:</span>
                              <span class="text-primary font-weight-bolder">{{ $t('spaces.info.capacity_value', {
                                min: space.office_short_info.capacity[0] || 0,
                                max: space.office_short_info.capacity[1] || 0,
                              }) }}</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-2">
                              <span class="text-muted font-weight-bolder mr-2">{{ $t('spaces.info.cost') }}:</span>
                              <span class="text-primary font-weight-bolder">{{ $t('spaces.info.rent_value', {
                                cost: space.office_short_info.min_cost,
                                symbol: space.currency.symbol
                              }) }}</span>
                            </div>
                            <div v-if="meetingRoomsAmount" class="d-flex align-items-center justify-content-between mb-2">
                              <span class="text-muted font-weight-bolder mr-2">{{ $t('spaces.info.meeting_rooms') }}:</span>
                              <span class="text-primary font-weight-bolder">{{space.meeting_rooms_count}}</span>
                            </div>
                          </div>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--begin::facilities-->
                      <div class="mt-3">
                        <span v-for="facility in space.facilities" :key="facility.id"
                      class="label font-weight-bold label-inline label-light-success mr-2 mb-2">{{facility.name}}</span>
                      </div>
                      <!--end::facilities-->
                    </div>
                  </div>
                  <div>
                    <!--begin::Separator-->
                    <div class="separator separator-solid my-5"></div>
                    <!--end::Separator-->
                    <!--begin::Bottom-->
                    <div class="d-flex align-items-center justify-content-between flex-wrap">
                      <a v-if="space.phone" :href="'tel:' + space.phone" class="btn btn-text-dark-50 btn-icon-primary font-weight-bold btn-hover-bg-light">
                        <font-awesome-icon icon="phone-alt" class="mr-1" />
                        {{space.phone}}
                      </a>
                      <router-link
                          :to="'/spaces/' + space.id"
                          v-slot="{ href, route, navigate, isActive, isExactActive }"
                      >
                        <a :href="href" @click="navigate" class="btn btn-primary">
                          {{ $t('spaces.view') }}
                        </a>
                      </router-link>
                    </div>
                    <!--end::Bottom-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Info-->
              </div>
              <!--end::Top-->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
let cookie = require('vue-cookie');

export default {
  components: { VueSlickCarousel },
  computed: {
    ...mapGetters({
      coworkingList: 'spaces/coworking',
      spaceList: 'spaces/list',
      countList: 'spaces/countList',
      loaded: 'spaces/loaded'
    }),
    setCoworkingName() {
      let key = 'coworkingId_' + localStorage.getItem('currentUserId');
      let coworkingId = localStorage.getItem(key) || '';
      let workSpace = this.coworkingList.find(item => item.id === parseInt(coworkingId));
      if (workSpace && workSpace.name) return workSpace.name;
    },
    setImageWidth() {
      return Math.floor(window.innerWidth);
    }
  },
  data: () => {
    return {
      meetingRoomsAmount: false
    }
  },
  mounted() {
    this.meetingRoomsAmount = window.__env.meeting_rooms_amount;
  },
  methods: {
    go(link) {
      this.$router.push(link)
    }
  }
}
</script>

<style>
  .slider-width {
    width: 100%;
  }

  @media (min-width: 768px) {
    .slider-width {
      max-width: 400px;
    }
  }
</style>
